/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .wm-grid {
  width: 100%;
}
.altai-theme-admin .wm-grid-row {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.altai-theme-admin .wm-grid-row .wm-grid-row-inner {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.altai-theme-admin .wm-grid-col {
  display: flex;
  margin: 25PX 0 25px 0;
}
.altai-theme-admin .wm-grid-hdivider {
  clear: both;
  min-height: 1px;
}
.altai-theme-admin .wm-grid-hdivider.dragover {
  height: 50px;
  background-color: rgba(204, 204, 204, 0.7);
  border: 1px dashed #000;
}
.altai-theme-admin .wm-resize-handler {
  width: 25px;
  cursor: ew-resize;
}
.altai-theme-admin .wm-resize-handler:hover {
  background-color: transparent;
}
.altai-theme-admin .wm-grid-col-windows {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.2s ease-in-out;
  /*border-top: 3px solid @color-toolbar-window-topborder;*/
}
.altai-theme-admin .wm-grid-col-windows:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
